import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import About from "./homepage/About";
import Clients from "./homepage/Clients";
import Blog from "./homepage/Blog";
import Detail from "./homepage/Detail";
import Support from "./homepage/Support";
import Faq from "./homepage/Faq";
import Community from "./homepage/Community";
import Terms from "./homepage/Terms";
import Privacy from "./homepage/Privacy";

import FindJobs from "./homepage/FindJobs";
import FindJobsSearch from "./homepage/FindJobsSearch";
import FindJobsDetail from "./homepage/FindJobsDetail";

import PostJobs from "./homepage/PostJobs";
import PostJobsForm from "./homepage/PostJobsForm";

import SaveJobs from "./homepage/SaveJobs";

import ErrorPage from "./homepage/Error";

import Login from "./Auth/Login";
import Signup from "./Auth/SIgnup";
import Jobs from "./Auth/Jobs";
import ResetPassword from "./Auth/ResetPassword";
import Verify from "./Auth/Verify";
import NewPassword from "./Auth/NewPassword";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Routes>
          <Route path="/" element={<Homepage />}/>
          <Route path="/save-jobs" element={<SaveJobs />}/>
          <Route path="/post-jobs" element={<PostJobs />}/>
          <Route path="/post-jobs/form" element={<PostJobsForm />}/>
          <Route path="/find-jobs" element={<FindJobs />}/>
          <Route path="/find-jobs/search" element={<FindJobsSearch />}/>
          <Route path="/find-jobs/detail" element={<FindJobsDetail />}/>
          <Route path="/client" element={<Clients />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/detail" element={<Detail />}/>
          <Route path="/support" element={<Support />}/>
          <Route path="/faq" element={<Faq />}/>
          <Route path="/community" element={<Community/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/sign-up" element={<Signup/>}/>
          <Route path="/select-job" element={<Jobs/>}/>
          <Route path="/reset-password" element={<ResetPassword/>}/>
          <Route path="/verify" element={<Verify/>}/>
          <Route path="/new-password" element={<NewPassword/>}/>
          <Route path="/*" element={<ErrorPage />}/>
        </Routes>
      </Router>
    </Fragment>
  );
};

export default Index;
