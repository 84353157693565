const ErrorPage = () => {
    return (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-6">
                    <img src="./../images/404.jpg" className='error-404' alt="" />
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;