import React from 'react'
import { useRef } from 'react';
import Slider from "react-slick";


const SliderTestiGrid = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        // appendDots: dots => {
        //     return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={20} />;
        // },
        responsive: [
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickPrev();
    };

    const next = () => {
        slider1.current.slickNext();
    };
    return (
        <div className="position-relative">
            <div className="wrapper__arrow-width">
                <svg className='pointer left' onClick={previous} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17.6799 20.7067L12.9866 16L17.6799 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg className='pointer right' onClick={next} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.3201 20.7067L19.0134 16L14.3201 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
            <Slider ref={slider1} {...settings} className='wrapper__slider-testimonial'>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="items">
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="wrapper__card-testi text-center">
                            <img src="./../images/quote-up.png" className='mb-3 mx-auto' alt="" />

                            <h5 className='medium font__size--20 text__20-1024 lh__4 mb-0'>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h5>

                            <img src="./../images/sdsda.png" className='my-4 mx-auto' alt="" />
                            <div className="d-flex justify-content-center align-items-center user">
                                <img src="./../images/Ellipse 1.png" alt="" />
                                <div className='ml-2 text-left'>
                                    <h5 className='medium font__size--16 text__16-1024 mb-0'>Davis Rosser</h5>
                                    <p className='mb-0 normal font__size--14 text__14-1024'>Director of Content Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SliderTestiGrid