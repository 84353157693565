import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Comment from "../../component/Other/Comment";
import CommentNested from "../../component/Other/CommentNested";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Community = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="text-center mb-4">
                            <h4 className="medium font__size--48 text__48-1024 text__48-sm">For the Community, by the <br className="d-none d-sm-block" /> Community</h4>
                        </div>

                        <div className="row">
                            <div className="col-md-7 mb-4 mb-md-0">
                                <img src="./../images/fghfghgfjg.png" className="w-100 images__community" alt="" />
                            </div>
                            <div className="col-md-5">
                                <div className="wrapper__card-about bg__blue">
                                    <img src="./../images/sfssfs.png" className="mb-4" alt="" />

                                    <h4 className="medium font__size--24 text__24-1024 color__white mb-3">Working on Wisc</h4>
                                    <p className="normal font__size--24 text__24-1024 color__white lh__4 mb-4">In this journey, you'll get an introduction to working on Wisc, submitting proposals, and tips for landing your first job.</p>

                                    <img src="./../images/fgfg (1).png" alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mb-4 mb-md-0">
                                <h4 className="medium font__size--24 text__24-1024 mb-4">Community Activity</h4>

                                <CommentNested />
                                <hr className='my-4' />
                                <Comment />
                                <hr className='my-4' />
                                <Comment />
                                <hr className='my-4' />

                            </div>
                            <div className="col-md-4">
                                <h4 className="medium font__size--24 text__24-1024 mb-4">Latest articles</h4>

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Development
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">What to expect on your first technical interview?</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Design
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">8 innovative design ideas to make your resume pop</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Design
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">How to build a great team of marketing experts</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <h4 className="mt-4 medium font__size--24 text__24-1024 mb-4">Categories</h4>

                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Development</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Design</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Marketing</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </section>


                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Community;
