import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import CardArticle from "../../component/Card/CardArticle";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Blog = (props) => {
    const blogs = [
        {
          img: "./../images/ghjghjh.png",
          tag: "Designer",
          title: "5 Tips to Be Prepared for 2021 Digital Marketing Trends",
          desc: "Stay ahead of the curve with these actionable tips to navigate the dynamic landscape of digital marketing in 2021.",
          date: "June 27, 2022",
        },
        {
          img: "./../images/rr (1).jfif",
          tag: "Development",
          title: "What to Expect on Your First Technical Interview?",
          desc: "Get insights into the technical interview process and prepare effectively for a successful outcome in your first interview.",
          date: "June 27, 2022",
        },
        {
          img: "./../images/rr (5).jfif",
          tag: "Designer",
          title: "8 Innovative Design Ideas to Make Your Resume Pop",
          desc: "Transform your resume with these creative design ideas that will make you stand out in the competitive job market.",
          date: "June 27, 2022",
        },
        {
          img: "./../images/rr (4).jfif",
          tag: "Designer",
          title: "How to Build a Great Team of Marketing Experts",
          desc: "Learn effective strategies to assemble and lead a high-performing marketing team for success in today's competitive business landscape.",
          date: "June 27, 2022",
        },
        {
          img: "./../images/rr (3).jfif",
          tag: "Marketing",
          title: "The 4 JavaScript Frameworks with More Active Job Openings",
          desc: "Explore the top JavaScript frameworks that are in high demand, and boost your career prospects with these sought-after skills.",
          date: "June 27, 2022",
        },
        {
          img: "./../images/rr (2).jfif",
          tag: "Development",
          title: "How to Start Your Career as a UI/UX Designer",
          desc: "Embark on a rewarding journey in UI/UX design by following these essential steps and building a solid foundation for your career.",
          date: "June 27, 2022",
        },
      ];      
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 mb-4 mb-lg-0">
                                <div className="wrapper__card-about bg__blue">
                                    <img src="./../images/News.png" className="mb-4" alt="" />

                                    <h4 className="medium font__size--32 text__32-1024 color__white mb-3">Resource & <br />Learning Center</h4>
                                    <p className="normal font__size--24 text__24-1024 color__white lh__4 mb-5">Welcome to our Resource & Learning Center, your hub for valuable insights and educational content. Here, we provide a wealth of resources to empower your learning journey.</p>

                                    <img src="./../images/fgfg (1).png" alt="" />

                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="wrapper__photo-grid blog d-flex">
                                    <div className="position-relative item first">
                                        <img src="./../images/ar (3).png" className="img-1" alt="" />
                                        <div className="bg"></div>
                                        <div className="tag medium font__size--16 text__16-1024 color__white">For Designer</div>
                                    </div>
                                    <div className="item wrap-1">
                                        <div className="position-relative wrap-1">
                                            <img src="./../images/ar (2).png" alt="" />
                                            <div className="bg"></div>
                                            <div className="tag medium font__size--16 text__16-1024 color__white">For Developer</div>
                                        </div>
                                        <div className="position-relative wrap-1">
                                            <img src="./../images/ar (1).png" alt="" />
                                            <div className="bg"></div>
                                            <div className="tag medium font__size--16 text__16-1024 color__white">For Marketers</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <h4 className="medium font__size--32 text__32-1024 mb-5">Latest Articles</h4>

                        <div className="row mb-4">
                            {
                                blogs.map((obj) => {
                                    return <div className="col-md-6 col-lg-4 mb-4">
                                        <CardArticle data={obj} />
                                    </div>
                                })
                            }
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div className="wrapper__pagination d-flex align-items-center">
                                <svg className='pointer left' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.6799 20.7067L12.9866 16L17.6799 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className="mx-2 list">
                                    <div className="dot active"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                <svg className='pointer right' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.0001 29.3333C23.3639 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.63616 23.3639 2.66663 16.0001 2.66663C8.63628 2.66663 2.66675 8.63616 2.66675 16C2.66675 23.3638 8.63628 29.3333 16.0001 29.3333Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14.3201 20.7067L19.0134 16L14.3201 11.2933" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <h5 className="mb-0 medium font__size--18 text__18-1024">1/4</h5>
                        </div>

                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Blog;
