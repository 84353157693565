import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Privacy = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4 mb-4 mb-md-0">

                                <h4 className="medium font__size--32 text__32-1024 text__32-xs">Privacy Policy</h4>
                                <p className="normal font__size--16 text__16-1024 color__gray-1 mb-4">The <span className="medium">Wisc</span> Terms of Service is comprised of the following agreements:</p>

                                <ul className="wrapper__list-number">
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center">
                                            <div className="number">
                                                1
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">User Agrement</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center active">
                                            <div className="number">
                                                2
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">Privacy Policy</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" className="d-flex color__black align-items-center">
                                            <div className="number">
                                                3
                                            </div>
                                            <p className="mb-0 ml-2 medium font__size--16 text__16-1024">Direct Contracts Terms</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7 ml-md-5">
                                <h4 className="medium font__size--20 text__20-1024 mb-4">User Agreement</h4>

                                <p className="normal font__size--16 text__16-1024 mb-4">Welcome to our User Agreement. This agreement outlines the terms and conditions that govern your use of our platform. Please read this carefully to understand your rights and responsibilities while using our services.</p>

                                <p className="medium font__size--16 text__16-1024 mb-4">By accessing or using our platform, you agree to be bound by the terms and conditions outlined in this User Agreement. If you do not agree to these terms, please refrain from using our services.</p>

                                <h5 className="medium font__size--16 text__16-1024">1. How It Works</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">Learn about how our platform works and the steps involved in utilizing our services effectively. Understanding these processes will enhance your experience on our platform.</p>

                                <h5 className="medium font__size--16 text__16-1024">2. Fees and Payments</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">This section details the fees associated with our platform, including any charges for freelancers and clients. Make sure to review this information to stay informed about our pricing structure.</p>

                                <h5 className="medium font__size--16 text__16-1024">3. User Responsibilities</h5>
                                <p className="normal font__size--16 text__16-1024 mb-4">Understand your responsibilities as a user of our platform. This includes adhering to our community guidelines, respecting other users, and maintaining the integrity of our community.</p>
                            </div>

                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Privacy;
