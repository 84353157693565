import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Support = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="wrapper__wrap-info">
                                    <h4 className="medium font__size--48 text__48-1024 text__48-sm">Support</h4>
                                    <p className="normal font__size--16 text__16-1024 color__gray-1 mb-4">Fill up the form and our team will get back to you with in 24 hours.</p>
                                
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="wrapper__icon-info d-flex align-items-center">
                                            <img src="./../images/call.png" alt="" />
                                        </div>
                                        <h5 className="mb-0 ml-3 medium font__size--16 text__16-1024">+1 432 1234 234</h5>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="wrapper__icon-info d-flex align-items-center">
                                            <img src="./../images/sms.png" alt="" />
                                        </div>
                                        <h5 className="mb-0 ml-3 medium font__size--16 text__16-1024">hello@wisc.com</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="wrapper__icon-info d-flex align-items-center">
                                            <img src="./../images/fdsfdf.png" alt="" />
                                        </div>
                                        <h5 className="mb-0 ml-3 medium font__size--16 text__16-1024">105 Street, Seatle-US</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 ml-lg-5">
                                <div className="form-group mb-4">
                                    <label htmlFor="" className="medium font__size--14 text__14-1024 mb-3">Name</label>
                                    <input type="text" value="Lincoln Baptista" className="medium font__size--14 text__14-1024 color__gray-1 wrapper__field-input-contact" />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="" className="medium font__size--14 text__14-1024 mb-3">Email</label>
                                    <input type="text" value="lincolnbaptista@gmail.com" className="medium font__size--14 text__14-1024 color__gray-1 wrapper__field-input-contact" />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="" className="medium font__size--14 text__14-1024 mb-3">Phone</label>
                                    <input type="text" value="+62 862732625134" className="medium font__size--14 text__14-1024 color__gray-1 wrapper__field-input-contact" />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="" className="medium font__size--14 text__14-1024 mb-3">How can we help?</label>
                                    <textarea className="medium font__size--14 text__14-1024 color__gray-1 wrapper__field-input-contact textarea" placeholder="Type here..." name="" id="" cols="30" rows="5"></textarea>
                                </div>

                                <div className="text-right">
                                    <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__blue shadow color__white rounded__50">Send Message</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Support;
