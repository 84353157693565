import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";
import SliderTestimonial from "../../component/Slider/SliderTestimonial";

const About = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 mb-4 mb-lg-0">
                                <div className="wrapper__card-about bg__blue">
                                    <div className="d-flex align-items-center mb-4">
                                        <img src="./../images/sfssf.png" alt="" />
                                        <span className="ml-2 semi-bold font__size--20 text__20-1024 color__white">Wisc</span>
                                    </div>

                                    <h4 className="medium font__size--32 text__32-1024 color__white mb-3">About Us</h4>
                                    <p className="normal font__size--24 text__24-1024 color__white lh__4 mb-0">Welcome to Wisc, where we're dedicated to transforming your career journey. At Wisc, we believe in creating opportunities that empower individuals to find fulfilling work and realize their full potential. Our mission is to connect talent with the right opportunities, fostering growth and success for both candidates and employers.</p>

                                    <img src="./../images/fgfg (1).png" className="my-4" alt="" />

                                    <div>
                                        <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__white color__blue shadow rounded__50">Join Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="wrapper__photo-grid d-flex">
                                    <div className="item wrap-1">
                                        <img src="./../images/ggtt.png" alt="" />
                                        <img src="./../images/fgfg (3).png" alt="" />
                                    </div>
                                    <img src="./../images/fgfg (2).png" className="item img-1" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="d-lg-flex justify-content-between align-items-center">
                            <h5 className="mb-lg-0 mb-4 medium font__size--24 text__24-1024 color__gray-1 text-center text-lg-left">Trusted by:</h5>
                            <div className="wrapper__brand d-flex flex-wrap flex-md-nowrap align-items-center justify-content-center justify-content-lg-end">
                                <img src="./../images/yt (1).png" alt="" />
                                <img src="./../images/yt (4).png" alt="" />
                                <img src="./../images/yt (3).png" alt="" />
                                <img src="./../images/yt (2).png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h3 className="medium font__size--48 text__48-1024 text__48-sm">Our numbers</h3>
                            <p className="normal font__size--16 text__16-1024">As the fastest-growing online Job board, our mission is to help <br className="d-none d-sm-block" /> great individuals connect with great companies.</p>
                        </div>

                        <div className="wrapper__number-wrap flex-wrap flex-md-nowrap d-flex align-items-center justify-content-center">
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (1).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">20,583<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Job Posted</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (4).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">3,896<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Successful hires</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (3).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">581<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Verified companies</p>
                                </div>
                            </div>
                            <div className="wrapper__card-number mb-4 mb-md-0 text-center">
                                <div>
                                    <img src="./../images/ic (2).png" alt="" />
                                    <h5 className="medium font__size--32 text__32-1024 my-3">100K<span className="color__blue">+</span></h5>
                                    <p className="mb-0 normal font__size--14 text__14-1024">Monthly visits</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h3 className="medium font__size--48 text__48-1024 text__48-sm">Trusted by leading brands <br className="d-none d-sm-block" /> and startups</h3>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <SliderTestimonial />
                            </div>
                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default About;
