import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import AccordionInfo from "../../component/Accordion/AccordionInfo";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Faq = (props) => {
    const listData = [
        {
          title: "What is Wisc?",
          desc: "Learn about the purpose and functionality of Wisc. Discover how Wisc connects job seekers with employers and facilitates the job search process."
        },
        {
          title: "How can I post a free job opening on Wisc?",
          desc: "Explore the step-by-step process of posting a job opening for free on Wisc. Find out how to attract qualified candidates to your job opportunity."
        },
        {
          title: "What's the cost to post a featured job opening on Wisc?",
          desc: "Understand the pricing structure for featuring job openings on Wisc. Explore the benefits of featuring your job posts to enhance visibility and reach."
        },
        {
          title: "Are there bulk discounts for featured job openings?",
          desc: "Discover potential discounts for businesses posting multiple job openings on Wisc. Learn about cost-effective options for featuring multiple job opportunities."
        },
        {
          title: "How long does it take to get a free job opening approved on Wisc?",
          desc: "Get insights into the approval process for free job postings on Wisc. Understand the timeline for your job openings to go live and attract applicants."
        },    
        ];
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-10">
                                <h4 className="medium font__size--24 text__24-1024 mb-4">Maybe your question is have been answered, <br className="d-none d-sm-block" /> check this out!</h4>
                                {
                                    listData.map((obj) => {
                                        return <div className="mb-4 pb-3">
                                            <AccordionInfo data={obj} />
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Faq;
