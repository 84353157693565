import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <section className='bg__darkblue pb-4'>
      <div className="container">
        <div className="row mb-4 mb-sm-5">
          <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
            <div className="d-flex align-items-center mb-3">
              <img src="./../images/Wisc-dark.jpg" alt="" width={100} />
            </div>
            <p className='normal font__size--16 text__16-1024 color__white'>We are taking action to help our freelancers, our clients, and the people of Ukraine—and so can you.</p>
          </div>
          <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">

            <div className="wrapper__menu-footer d-flex flex-wrap align-items-center justify-content-start  justify-content-md-center">
              <NavLink to="/" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>Find Talent</NavLink>
              <NavLink to="/client" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>Clients</NavLink>
              <NavLink to="/blog" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>Blog</NavLink>
              <NavLink to="/faq" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>FAQ</NavLink>
              <NavLink to="/about" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>About Us</NavLink>
            </div>

          </div>
          <div className="col-md-6 mb-4 mb-lg-0 col-lg-4">
            <h5 className='medium font__size--16 text__16-1024 color__white opacity__5 mb-4'>Newsletter</h5>

            <div className="d-flex align-items-center">
              <input type="text" placeholder='Your email here' className='normal color__white font__size--16 text__16-1024 wrapper__input-field sub' />
              <button className='semi-bold font__size--16 text__16-1024 btn btn__blue color__white shadow rounded__50 ml-3'>Send</button>
            </div>
          </div>
        </div>
        <img src="./../images/Line@2x.png" className='w-100 mb-4' alt="" />
        <div className="d-md-flex aligen-items-center justify-content-between position-relative">
          <p className='mb-0 normal font__size--16 text__16-1024 color__white'>© 2022 Wisc® Global Inc.</p>
          <div className="wrapper__sosmed center d-flex align-items-center justify-content-md-center my-3 my-md-0">
            <a href="#!">
              <img src="./../images/svg.png" alt="" />
            </a>
            <a href="#!">
              <img src="./../images/svg (1).png" alt="" />
            </a>
            <a href="#!">
              <img src="./../images/svg (2).png" alt="" />
            </a>
            <a href="#!">
              <img src="./../images/svg (3).png" alt="" />
            </a>
            <a href="#!">
              <img src="./../images/svg (4).png" alt="" />
            </a>
          </div>

          <div className="d-flex align-items-center">
            <NavLink to="/terms" className='medium font__size--16 text__16-1024 color__white opacity__5 hover'>Terms of Service</NavLink>
            <NavLink to="/privacy" className='medium font__size--16 text__16-1024 color__white opacity__5 hover ml-3'>Privacy Policy</NavLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer