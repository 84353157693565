import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false)
  return (
    <Fragment>
      <div className={"wrapper__side-nav-mobile d-flex d-xl-none " + (toogleMenu ? "active" : "")}>
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                class="navbar-brand semi-bold font__size--20 text__20-1024 bold "
              >
                <div className="d-flex align-items-center">
                  <img src="./../images/Wisc.png" alt="" width={80} />
                </div>
              </NavLink>
              <img src="./../images/close (1).png" onClick={() => setToogleMenu(!toogleMenu)} className="pointer" alt="" />
            </div>
            {
              props.auth ? <div className="menu">
                <NavLink
                  to="/find-jobs"
                  class="medium font__size--16 text__16-1024 color__black opacity__7 hover"
                >
                  <div className="d-flex align-items-center">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.16683 14.4999C4.40016 14.4999 1.3335 11.4333 1.3335 7.66659C1.3335 3.89992 4.40016 0.833252 8.16683 0.833252C11.9335 0.833252 15.0002 3.89992 15.0002 7.66659C15.0002 11.4333 11.9335 14.4999 8.16683 14.4999ZM8.16683 1.83325C4.94683 1.83325 2.3335 4.45325 2.3335 7.66659C2.3335 10.8799 4.94683 13.4999 8.16683 13.4999C11.3868 13.4999 14.0002 10.8799 14.0002 7.66659C14.0002 4.45325 11.3868 1.83325 8.16683 1.83325Z" fill="#636366" />
                      <path d="M15.1666 15.1666C15.04 15.1666 14.9133 15.12 14.8133 15.02L13.48 13.6866C13.2866 13.4933 13.2866 13.1733 13.48 12.98C13.6733 12.7866 13.9933 12.7866 14.1866 12.98L15.52 14.3133C15.7133 14.5066 15.7133 14.8266 15.52 15.02C15.42 15.12 15.2933 15.1666 15.1666 15.1666Z" fill="#636366" />
                    </svg>
                    <span className="ml-2">Find Job</span>
                  </div>
                </NavLink>
                <hr />
                <NavLink to="/post-jobs"
                  class="medium font__size--16 text__16-1024 color__black opacity__7 hover"
                >
                  <div className="d-flex align-items-center">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.1667 15.1666H5.83332C2.75332 15.1666 2.17999 13.7333 2.03332 12.3399L1.53332 6.99993C1.45999 6.29993 1.43999 5.26659 2.13332 4.49326C2.73332 3.82659 3.72666 3.50659 5.16666 3.50659H11.8333C13.28 3.50659 14.2733 3.83326 14.8667 4.49326C15.56 5.26659 15.54 6.29993 15.4667 7.00659L14.9667 12.3333C14.82 13.7333 14.2467 15.1666 11.1667 15.1666ZM5.16666 4.49993C4.03999 4.49993 3.26666 4.71992 2.87332 5.15992C2.54666 5.51992 2.43999 6.07326 2.52666 6.89993L3.02666 12.2399C3.13999 13.2933 3.42666 14.1666 5.83332 14.1666H11.1667C13.5667 14.1666 13.86 13.2933 13.9733 12.2333L14.4733 6.90659C14.56 6.07326 14.4533 5.51992 14.1267 5.15992C13.7333 4.71992 12.96 4.49993 11.8333 4.49993H5.16666Z" fill="#636366" />
                      <path d="M11.1668 4.49992C10.8935 4.49992 10.6668 4.27325 10.6668 3.99992V3.46659C10.6668 2.27992 10.6668 1.83325 9.0335 1.83325H7.96683C6.3335 1.83325 6.3335 2.27992 6.3335 3.46659V3.99992C6.3335 4.27325 6.10683 4.49992 5.8335 4.49992C5.56016 4.49992 5.3335 4.27325 5.3335 3.99992V3.46659C5.3335 2.29325 5.3335 0.833252 7.96683 0.833252H9.0335C11.6668 0.833252 11.6668 2.29325 11.6668 3.46659V3.99992C11.6668 4.27325 11.4402 4.49992 11.1668 4.49992Z" fill="#636366" />
                      <path d="M8.49984 11.1667C6.6665 11.1667 6.6665 10.0333 6.6665 9.35333V8.66667C6.6665 7.72667 6.89317 7.5 7.83317 7.5H9.1665C10.1065 7.5 10.3332 7.72667 10.3332 8.66667V9.33333C10.3332 10.0267 10.3332 11.1667 8.49984 11.1667ZM7.6665 8.5C7.6665 8.55333 7.6665 8.61333 7.6665 8.66667V9.35333C7.6665 10.04 7.6665 10.1667 8.49984 10.1667C9.33317 10.1667 9.33317 10.06 9.33317 9.34667V8.66667C9.33317 8.61333 9.33317 8.55333 9.33317 8.5C9.27984 8.5 9.21984 8.5 9.1665 8.5H7.83317C7.77984 8.5 7.71984 8.5 7.6665 8.5Z" fill="#636366" />
                      <path d="M9.8335 9.84675C9.58683 9.84675 9.36683 9.66008 9.34016 9.40674C9.30683 9.13341 9.50016 8.88008 9.7735 8.84675C11.5335 8.62675 13.2202 7.96008 14.6402 6.92675C14.8602 6.76008 15.1735 6.81341 15.3402 7.04008C15.5002 7.26008 15.4535 7.57341 15.2268 7.74008C13.6668 8.87341 11.8268 9.60008 9.8935 9.84675C9.8735 9.84675 9.8535 9.84675 9.8335 9.84675Z" fill="#636366" />
                      <path d="M7.16666 9.85343C7.14666 9.85343 7.12666 9.85343 7.10666 9.85343C5.27999 9.64676 3.49999 8.9801 1.95999 7.92676C1.73333 7.77343 1.67333 7.4601 1.82666 7.23343C1.97999 7.00676 2.29333 6.94676 2.51999 7.1001C3.92666 8.0601 5.54666 8.66676 7.21333 8.8601C7.48666 8.89343 7.68666 9.1401 7.65333 9.41343C7.63333 9.66676 7.41999 9.85343 7.16666 9.85343Z" fill="#636366" />
                    </svg>

                    <span className="ml-2">Post a Job</span>
                  </div>
                </NavLink>
                <hr />
                <NavLink to="/save-jobs"
                  class="medium font__size--16 text__16-1024 color__black opacity__7 hover"
                >
                  <div className="d-flex align-items-center">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.50016 14.4333C8.2935 14.4333 8.0935 14.4066 7.92683 14.3467C5.38016 13.4733 1.3335 10.3733 1.3335 5.79332C1.3335 3.45998 3.22016 1.56665 5.54016 1.56665C6.66683 1.56665 7.72016 2.00665 8.50016 2.79332C9.28016 2.00665 10.3335 1.56665 11.4602 1.56665C13.7802 1.56665 15.6668 3.46665 15.6668 5.79332C15.6668 10.38 11.6202 13.4733 9.0735 14.3467C8.90683 14.4066 8.70683 14.4333 8.50016 14.4333ZM5.54016 2.56665C3.7735 2.56665 2.3335 4.01332 2.3335 5.79332C2.3335 10.3466 6.7135 12.88 8.2535 13.4066C8.3735 13.4466 8.6335 13.4466 8.7535 13.4066C10.2868 12.88 14.6735 10.3533 14.6735 5.79332C14.6735 4.01332 13.2335 2.56665 11.4668 2.56665C10.4535 2.56665 9.5135 3.03998 8.90683 3.85998C8.72016 4.11332 8.2935 4.11332 8.10683 3.85998C7.48683 3.03332 6.5535 2.56665 5.54016 2.56665Z" fill="#636366" />
                    </svg>
                    <span className="ml-2">Saved Job</span>
                  </div>
                </NavLink>
              </div> : <div className="menu">
                <NavLink to="/" className="medium font__size--16 text__16-1024 color__black">Find Talent</NavLink>
                <hr />
                <NavLink to="/client" className="medium font__size--16 text__16-1024 color__black">Clients</NavLink>
                <hr />
                <NavLink
                  to="/community" className="medium font__size--16 text__16-1024 color__black">Community</NavLink>
                <hr />
                <NavLink to="/blog" className="medium font__size--16 text__16-1024 color__black">Blog</NavLink>
                <hr />
                <NavLink to="/about" className="medium font__size--16 text__16-1024 color__black">About Us</NavLink>
                <hr />
                <div className="d-flex align-items-center">
                  <NavLink to="/login" className={"semi-bold rounded__50 btn__lg-sign font__size--16 text__16-1024 no__opacity shadow btn btn__outlined--blue color__blue"}>Log In</NavLink>
                  <NavLink to="/sign-up" className={"ml-2 semi-bold rounded__50 btn__lg-sign font__size--16 text__16-1024 btn shadow btn__blue color__white"}>Sign Up</NavLink>
                </div>
              </div>
            }

          </div>
        </div>
      </div >
      <div className="bg__wrap-menu d-xl-none" onClick={() => setToogleMenu(!toogleMenu)}></div>
      <nav
        className={"navbar navbar-expand-xl wrapper__navbar position-relative z-2 " + (props.auth ? "auth" : "")}
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <div className="d-flex align-items-center">
            <div
              onClick={() => setToogleMenu(!toogleMenu)}
              class="nav__button d-xl-none position-relative mr-3 flex-shrink-0"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <NavLink
              to="/"
              exact
              class="navbar-brand font__size--20 text__20-1024 semi-bold  color__black"
            >
              <div className="d-flex align-items-center">
                <img src="./../images/Wisc.png" alt="" width={80} />
              </div>
            </NavLink>
          </div>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-3"
            id="navbarSupportedContent"
          >
            {
              props.auth ? <ul className="navbar-nav menu__center">
                <li className="nav-item">
                  <NavLink
                    to="/find-jobs"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    <div className="d-flex align-items-center">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.16683 14.4999C4.40016 14.4999 1.3335 11.4333 1.3335 7.66659C1.3335 3.89992 4.40016 0.833252 8.16683 0.833252C11.9335 0.833252 15.0002 3.89992 15.0002 7.66659C15.0002 11.4333 11.9335 14.4999 8.16683 14.4999ZM8.16683 1.83325C4.94683 1.83325 2.3335 4.45325 2.3335 7.66659C2.3335 10.8799 4.94683 13.4999 8.16683 13.4999C11.3868 13.4999 14.0002 10.8799 14.0002 7.66659C14.0002 4.45325 11.3868 1.83325 8.16683 1.83325Z" fill="#636366" />
                        <path d="M15.1666 15.1666C15.04 15.1666 14.9133 15.12 14.8133 15.02L13.48 13.6866C13.2866 13.4933 13.2866 13.1733 13.48 12.98C13.6733 12.7866 13.9933 12.7866 14.1866 12.98L15.52 14.3133C15.7133 14.5066 15.7133 14.8266 15.52 15.02C15.42 15.12 15.2933 15.1666 15.1666 15.1666Z" fill="#636366" />
                      </svg>
                      <span className="ml-2">Find Job</span>
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/post-jobs"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    <div className="d-flex align-items-center">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.1667 15.1666H5.83332C2.75332 15.1666 2.17999 13.7333 2.03332 12.3399L1.53332 6.99993C1.45999 6.29993 1.43999 5.26659 2.13332 4.49326C2.73332 3.82659 3.72666 3.50659 5.16666 3.50659H11.8333C13.28 3.50659 14.2733 3.83326 14.8667 4.49326C15.56 5.26659 15.54 6.29993 15.4667 7.00659L14.9667 12.3333C14.82 13.7333 14.2467 15.1666 11.1667 15.1666ZM5.16666 4.49993C4.03999 4.49993 3.26666 4.71992 2.87332 5.15992C2.54666 5.51992 2.43999 6.07326 2.52666 6.89993L3.02666 12.2399C3.13999 13.2933 3.42666 14.1666 5.83332 14.1666H11.1667C13.5667 14.1666 13.86 13.2933 13.9733 12.2333L14.4733 6.90659C14.56 6.07326 14.4533 5.51992 14.1267 5.15992C13.7333 4.71992 12.96 4.49993 11.8333 4.49993H5.16666Z" fill="#636366" />
                        <path d="M11.1668 4.49992C10.8935 4.49992 10.6668 4.27325 10.6668 3.99992V3.46659C10.6668 2.27992 10.6668 1.83325 9.0335 1.83325H7.96683C6.3335 1.83325 6.3335 2.27992 6.3335 3.46659V3.99992C6.3335 4.27325 6.10683 4.49992 5.8335 4.49992C5.56016 4.49992 5.3335 4.27325 5.3335 3.99992V3.46659C5.3335 2.29325 5.3335 0.833252 7.96683 0.833252H9.0335C11.6668 0.833252 11.6668 2.29325 11.6668 3.46659V3.99992C11.6668 4.27325 11.4402 4.49992 11.1668 4.49992Z" fill="#636366" />
                        <path d="M8.49984 11.1667C6.6665 11.1667 6.6665 10.0333 6.6665 9.35333V8.66667C6.6665 7.72667 6.89317 7.5 7.83317 7.5H9.1665C10.1065 7.5 10.3332 7.72667 10.3332 8.66667V9.33333C10.3332 10.0267 10.3332 11.1667 8.49984 11.1667ZM7.6665 8.5C7.6665 8.55333 7.6665 8.61333 7.6665 8.66667V9.35333C7.6665 10.04 7.6665 10.1667 8.49984 10.1667C9.33317 10.1667 9.33317 10.06 9.33317 9.34667V8.66667C9.33317 8.61333 9.33317 8.55333 9.33317 8.5C9.27984 8.5 9.21984 8.5 9.1665 8.5H7.83317C7.77984 8.5 7.71984 8.5 7.6665 8.5Z" fill="#636366" />
                        <path d="M9.8335 9.84675C9.58683 9.84675 9.36683 9.66008 9.34016 9.40674C9.30683 9.13341 9.50016 8.88008 9.7735 8.84675C11.5335 8.62675 13.2202 7.96008 14.6402 6.92675C14.8602 6.76008 15.1735 6.81341 15.3402 7.04008C15.5002 7.26008 15.4535 7.57341 15.2268 7.74008C13.6668 8.87341 11.8268 9.60008 9.8935 9.84675C9.8735 9.84675 9.8535 9.84675 9.8335 9.84675Z" fill="#636366" />
                        <path d="M7.16666 9.85343C7.14666 9.85343 7.12666 9.85343 7.10666 9.85343C5.27999 9.64676 3.49999 8.9801 1.95999 7.92676C1.73333 7.77343 1.67333 7.4601 1.82666 7.23343C1.97999 7.00676 2.29333 6.94676 2.51999 7.1001C3.92666 8.0601 5.54666 8.66676 7.21333 8.8601C7.48666 8.89343 7.68666 9.1401 7.65333 9.41343C7.63333 9.66676 7.41999 9.85343 7.16666 9.85343Z" fill="#636366" />
                      </svg>

                      <span className="ml-2">Post a Job</span>
                    </div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/save-jobs"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    <div className="d-flex align-items-center">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.50016 14.4333C8.2935 14.4333 8.0935 14.4066 7.92683 14.3467C5.38016 13.4733 1.3335 10.3733 1.3335 5.79332C1.3335 3.45998 3.22016 1.56665 5.54016 1.56665C6.66683 1.56665 7.72016 2.00665 8.50016 2.79332C9.28016 2.00665 10.3335 1.56665 11.4602 1.56665C13.7802 1.56665 15.6668 3.46665 15.6668 5.79332C15.6668 10.38 11.6202 13.4733 9.0735 14.3467C8.90683 14.4066 8.70683 14.4333 8.50016 14.4333ZM5.54016 2.56665C3.7735 2.56665 2.3335 4.01332 2.3335 5.79332C2.3335 10.3466 6.7135 12.88 8.2535 13.4066C8.3735 13.4466 8.6335 13.4466 8.7535 13.4066C10.2868 12.88 14.6735 10.3533 14.6735 5.79332C14.6735 4.01332 13.2335 2.56665 11.4668 2.56665C10.4535 2.56665 9.5135 3.03998 8.90683 3.85998C8.72016 4.11332 8.2935 4.11332 8.10683 3.85998C7.48683 3.03332 6.5535 2.56665 5.54016 2.56665Z" fill="#636366" />
                      </svg>
                      <span className="ml-2">Saved Job</span>
                    </div>
                  </NavLink>
                </li>


              </ul> : <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    exact
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    Find Talent
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/client"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    Clients
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/community"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    Community
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/blog"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    Blog
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about"
                    class="nav-link medium font__size--16 text__16-1024 color__black opacity__7 hover"
                  >
                    About Us
                  </NavLink>
                </li>
              </ul>
            }

          </div>

          {
            props.auth ? <div className="wrapper__notif-menu d-flex align-items-center ml-auto">
              <a href="#!" className="item"><img src="./../images/Notification.png" alt="" /></a>
              <a href="#!" className="item"><img src="./../images/Chat.png" alt="" /></a>
              <a href="#!" className="item user"><img src="./../images/sfsfsdf.png" alt="" /></a>
            </div> : <div className=" d-none d-xl-flex align-items-center ml-auto">
              <NavLink to="/login" className={"semi-bold rounded__50 font__size--16 text__16-1024 no__opacity shadow " + (props.type == "blue" ? "btn btn__outlined--blue color__blue " : "btn btn__outlined--white h__black color__white")}>Log In</NavLink>
              <NavLink to="/sign-up" className={"ml-3 semi-bold rounded__50 font__size--16 text__16-1024 btn shadow " + (props.type == "blue" ? "btn__blue color__white" : "btn__white color__blue")}>Sign Up</NavLink>
            </div>
          }



        </div>
      </nav>
    </Fragment >
  );
};

export default Navbar;
