import React from 'react'
import { NavLink } from 'react-router-dom'

const CardArticle = (props) => {
    return (
        <div className="wrapper__card-article">
            <div className="position-relative cover mb-3">
                <img src={props.data.img} alt="" />
                <div className="tag medium font__size--14 text__14-1024 color__white">{props.data.tag}</div>
            </div>

            <div className="desc">
                <h5 className='medium font__size--20 text__20-1024 mb-0'>{props.data.title}</h5>
                <p className='normal font__size--16 text__16-1024 my-3'>{props.data.desc}</p>

                <div className="d-flex align-items-center justify-content-between">
                    <div className='medium font__size--14 text__14-1024'>{props.data.date}</div>
                    <NavLink to="/blog/detail" className="d-flex align-items-center medium font__size--14 text__14-1024 color__blue">
                        Read More
                        <img src="./../images/arrow-right.png" className='ml-1' alt="" />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default CardArticle