import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";

const Detail = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <Navbar type="blue" />

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mb-4 mb-md-0">
                                <h2 className="medium font__size--40 text__40-1024 text__40-md text__40-mm mb-3">5 tips to be prepared for 2021 digital marketing trends</h2>

                                <div className="d-flex align-items-center mb-4">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> For Freelance
                                    </div>

                                    <h5 className="mb-0 semi-bold font__size--14 text__14-1024 color__gray-2 ml-3">Juni 27, 2022</h5>
                                </div>

                                <p className="normal font__size--18 text__18-1024 mb-4">Procurement can be challenging, especially with several roadblocks along the supply chain. Even still, the most significant liability could easily be the talent shortage. Following the pandemic, procuring top talent in a shifting economic landscape deserves more spotlight, especially amid a turbulent fiscal forecast.</p>
                                <p className="normal font__size--18 text__18-1024 mb-4">The changes businesses make now will likely decide whether or not they survive. Because managing procurement risks starts with being aware of them, streamlining procedures and taking advantage of technology — the only real solution to getting ahead of compliance issues.</p>

                                <div className="wrapper__left-height position-relative d-flex align-items-center">
                                    <div className="line"></div>
                                    <div className="ml-4">
                                        <h5 className="medium font__size--20 text__20-1024 color__gray-2 mb-4">“Inflation will put more pressure on chief procurement officers to manage their departments strictly within budget.” </h5>
                                        <h5 className="medium font__size--20 text__20-1024 color__gray-2 mb-0">- Ardent Partners Annual Research Report</h5>
                                    </div>
                                </div>

                                <img src="./../images/sadsdas.png" className="images__detail-blog my-4" alt="" />

                                <h5 className="medium font__size--18 text__18-1024 color__gray-2 mb-4">The #1 Compliance Pitfall: Worker Classification</h5>

                                <p className="normal font__size--18 text__18-1024 mb-4">A common freelance hiring issue originates from a poor chain of custody around worker classification data and worker classification itself. Without legal worker classification, your organization is in danger of costly litigation. If you should get audited, could you stand by your worker classification procedures?  </p>
                                <p className="normal font__size--18 text__18-1024 mb-4">Do you handle worker classification in-house, or is it outsourced to your managed service provider (MSP), who may outsource it to a vendor management solution (VMS)? Because many businesses are redistributing the responsibility of worker classification to a third party or still have paper processes in place, which is awfully precarious and, dare I say, archaic.   </p>
                                <p className="normal font__size--18 text__18-1024 mb-4">Remember, the third party you employ to carry out worker classification won't be in the hot seat, so to speak, if an audit occurs and the data isn't there. Tech solutions like Worksome indemnify enterprises using its state-of-the-art tool to classify workers. The Worksome Classify system can perform worker classification in minutes, not weeks or months, with 100% compliance guaranteed.</p>
                                <p className="normal font__size--18 text__18-1024 mb-4">Do you want to optimize processes to save time, make your budget go further and innovate while staying compliant? Book a free Worksome demo today!</p>
                            </div>
                            <div className="col-md-4">
                                <h4 className="medium font__size--24 text__24-1024 mb-4">Latest articles</h4>

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Development
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">What to expect on your first technical interview?</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Design
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">8 innovative design ideas to make your resume pop</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <div className="d-inline-block">
                                    <div className="wrapper__wrap-line d-flex align-items-center medium font__size--16 text__16-1024 color__blue">
                                        <span className="wrapper__dot-list mr-2"></span> Design
                                    </div>
                                </div>
                                <h5 className="medium font__size--24 text__24-1024 my-3">How to build a great team of marketing experts</h5>
                                <p className="medium font__size--14 text__14-1024 color__gray-2">Juni 27, 2022</p>

                                <hr />

                                <h4 className="mt-4 medium font__size--24 text__24-1024 mb-4">Categories</h4>

                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Development</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Design</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                                <a href="#!" className="color__black w-100 d-inline-block mb-4">
                                    <div className="d-flex w-100 align-items-center">
                                        <span className="medium w-100 font__size--20 text__20-1024">Marketing</span>
                                        <img src="./../images/fdsfdsf.png" className="ml-2" alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </section>

                <Opportunity />

                <Footer />

            </div >
        </Fragment >
    );
};

export default Detail;
