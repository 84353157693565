import React from 'react'
import { useRef } from 'react';
import Slider from "react-slick";

const SliderCardWork = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // responsive: [
        //     {
        //         breakpoint: 770,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 3,
        //         }
        //     },
        // ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (
        <div className="position-realtive">
            <div className="wrapper__arrow-icon pointer" onClick={previous}>
                <img src="./../images/arrow-circle-right.png" alt="" />
            </div>
            <Slider ref={slider1} {...settings} className='wrapper__slider-work'>
                <div className="items">
                    <div className="wrapper__card-work bg__blue">
                        <img src="./../images/user-search.png" className='mb-3' alt="" />
                        <h5 className='medium font__size--24 text__24-1024 color__white'>Find opportunities for every stage of your freelance career</h5>
                        <img src="./../images/Line.png" className='w-100 mt-5 mb-3' alt="" />
                        <a href="#!" className='semi-bold font__size--16 text__16-1024 btn btn__white shadow rounded__50 color__blue'>Join Now</a>
                    </div>
                </div>
                <div className="items">
                    <div className="wrapper__card-work bg__blue">
                        <img src="./../images/user-search.png" className='mb-3' alt="" />
                        <h5 className='medium font__size--24 text__24-1024 color__white'>Find opportunities for every stage of your freelance career</h5>
                        <img src="./../images/Line.png" className='w-100 mt-5 mb-3' alt="" />
                        <a href="#!" className='semi-bold font__size--16 text__16-1024 btn btn__white shadow rounded__50 color__blue'>Join Now</a>
                    </div>
                </div>
                <div className="items">
                    <div className="wrapper__card-work bg__blue">
                        <img src="./../images/user-search.png" className='mb-3' alt="" />
                        <h5 className='medium font__size--24 text__24-1024 color__white'>Find opportunities for every stage of your freelance career</h5>
                        <img src="./../images/Line.png" className='w-100 mt-5 mb-3' alt="" />
                        <a href="#!" className='semi-bold font__size--16 text__16-1024 btn btn__white shadow rounded__50 color__blue'>Join Now</a>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SliderCardWork