import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import Opportunity from "../../component/Other/Opportunity";
import SliderCardWork from "../../component/Slider/SliderCardWork";
import SliderTestimonial from "../../component/Slider/SliderTestimonial";

const Index = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative">
          <div className="position-relative z-2">
            <img src="./../images/Image (2).png" className="images__head-top d-none d-lg-block" alt="" />

            <Navbar />

            <section className="position-relative z-2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <h1 className="medium font__size--72 text__70-1024 text__70-md mb-0">Portal Job for Developer, Designer, and Marketers</h1>
                    <p className="normal font__size--16 text__16-1024 mt-4 mb-0">Jobs is a curated job board of the best jobs for developers, <br /> designers and marketers in the tech industry.</p>

                    <div className="wrapper__tab-filter-ngull my-4 mb-sm-5">
                      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link medium color__gray-1 font__size--14 text__14-1024 active" id="pills-Find-tab" data-toggle="pill" href="#pills-Find" role="tab" aria-controls="pills-Find" aria-selected="true">Find Job</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link medium color__gray-1 font__size--14 text__14-1024" id="pills-Talents-tab" data-toggle="pill" href="#pills-Talents" role="tab" aria-controls="pills-Talents" aria-selected="false">Find Talents</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-Find" role="tabpanel" aria-labelledby="pills-Find-tab">
                          <div className="wrapper__tab-grid">
                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/sdsdsf.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Search for job.." className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/location.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Location" className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/briefcase.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Department" className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <button className="btn btn__blue color__white shadow rounded__50 items mt-md-3 mt-lg-0">
                              <div className="d-flex align-items-center justify-content-center">
                                <img src="./../images/search-normal.png" alt="" />
                                <span className="semi-bold font__size--16 text__16-1024 text__16-1024 ml-2">Search</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="pills-Talents" role="tabpanel" aria-labelledby="pills-Talents-tab">
                          <div className="wrapper__tab-grid">
                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/sdsdsf.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Search for job.." className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/location.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Location" className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <div className="field__input d-flex align-items-center items">
                              <img src="./../images/briefcase.png" className="icon" alt="" />
                              <div className="ml-2">
                                <input type="text" placeholder="Department" className="medium font__size--14 text__14-1024" />
                              </div>
                            </div>

                            <button className="btn btn__blue color__white shadow rounded__50 items mt-md-3 mt-lg-0">
                              <div className="d-flex align-items-center justify-content-center">
                                <img src="./../images/search-normal.png" alt="" />
                                <span className="semi-bold font__size--16 text__16-1024 text__16-1024 ml-2">Search</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 className="medium font__size--18 text__18-1024 color__gray-1 mb-3">Trusted by:</h5>

                    <div className="d-flex align-items-center">
                      <img src="./../images/as (1).png" alt="" />
                      <img src="./../images/as (3).png" className="mx-4" alt="" />
                      <img src="./../images/as (2).png" alt="" />
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h3 className="medium font__size--48 text__48-1024 text__48-sm">Featured Job</h3>
              <p className="normal font__size--16 text__16-1024">Jobs is a curated job board of the best jobs for developers, designers <br className="d-none d-sm-block" /> and marketers in the tech industry.</p>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-11 col-xl-9">
                <div className="row">
                  <div className="col-md-4 bg__white wrapper__rounded-left">
                    <div className="wrapper__card-featured">
                      <img src="./../images/message-programming.png" alt="" />

                      <h4 className="my-3 meium font__size--24 text__24-1024 color__blue">Developer</h4>

                      <p className="normal font__size--16 text__16-1024 mb-3">Jobs is a curated job board of the best jobs for developers, designers and marketers in the tech industry.</p>

                      <ul className="mb-4">
                        <li className="medium font__size--18 text__18-1024">211K+ contracts</li>
                        <li className="medium font__size--18 text__18-1024">1,665 skills</li>
                      </ul>

                      <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__outlined--blue shadow color__blue rounded__50 d-inline-block no__opacity">Browse Job</a>
                    </div>
                  </div>
                  <div className="col-md-4 bg__blue">
                    <div className="wrapper__card-featured">
                      <img src="./../images/bezier.png" alt="" />

                      <h4 className="my-3 meium font__size--24 text__24-1024 color__white">Designer</h4>

                      <p className="normal font__size--16 text__16-1024 mb-3 color__white">Jobs is a curated job board of the best jobs for developers, designers and marketers in the tech industry.</p>

                      <ul className="mb-4 color__white">
                        <li className="medium font__size--18 text__18-1024">411K+ contracts</li>
                        <li className="medium font__size--18 text__18-1024">1,422 skills</li>
                      </ul>

                      <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__white shadow color__blue rounded__50 d-inline-block">Browse Job</a>
                    </div>
                  </div>
                  <div className="col-md-4 bg__white wrapper__rounded-right">
                    <div className="wrapper__card-featured">
                      <img src="./../images/status-up.png" alt="" />

                      <h4 className="my-3 meium font__size--24 text__24-1024 color__blue">Marketers</h4>

                      <p className="normal font__size--16 text__16-1024 mb-3">Jobs is a curated job board of the best jobs for developers, designers and marketers in the tech industry.</p>

                      <ul className="mb-4">
                        <li className="medium font__size--18 text__18-1024">234+ contracts</li>
                        <li className="medium font__size--18 text__18-1024">1,321 skills</li>
                      </ul>

                      <a href="#!" className="semi-bold font__size--16 text__16-1024 btn btn__outlined--blue shadow color__blue rounded__50 d-inline-block no__opacity">Browse Job</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="container z-2">
            <div className="wrapper__cliet-wrap bg__darkblue position-relative">
              <img src="./../images/Patern.png" className="images__ornamen-client" alt="" />
              <img src="./../images/Group 5.png" className="images__right-client d-none d-lg-block" alt="" />
              <div className="row position-relative z-2">
                <div className="col-lg-6">
                  <p className="medium font__size--20 text__20-1024 color__white">For Clients</p>
                  <h3 className="medium font__size--48 text__48-1024 text__48-sm color__white mb-5">Why businesses <br /> turn to Wisc</h3>

                  <div className="d-flex align-items-start mb-4">
                    <img src="./../images/star.png" alt="" />
                    <div className="ml-3">
                      <h5 className="medium font__size--24 text__24-1024 color__white">Proof of quality</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-1">Check any pro’s work samples, client reviews, and identity verification.</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start mb-4">
                    <img src="./../images/24-support.png" alt="" />
                    <div className="ml-3">
                      <h5 className="medium font__size--24 text__24-1024 color__white">Safe and secure</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-1">Focus on your work knowing we help protect your data and privacy. We’re here with 24/7 support if you need it.</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <img src="./../images/coin.png" alt="" />
                    <div className="ml-3">
                      <h5 className="medium font__size--24 text__24-1024 color__white">No cost until you hire</h5>
                      <p className="normal font__size--16 text__16-1024 color__gray-1">Interview potential fits for your job, negotiate rates, and only pay for work you approve.</p>
                    </div>
                  </div>

                </div>
              </div>
              <div className="text-right d-lg-none">
                <img src="./../images/Group 5.png" className="images__right-client" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h3 className="medium font__size--48 text__48-1024 text__48-sm">Find great work</h3>
              <p className="normal font__size--16 text__16-1024">Meet clients you’re excited to work with and take your career or <br /> business to new heights.</p>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row">
                  <div className="col-lg-5 mb-4 mb-lg-0">
                    <SliderCardWork />
                  </div>
                  <div className="col-lg-7">
                    <img src="./../images/sdfsdf.png" className="images__wrap-work" alt="" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>


        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h3 className="medium font__size--48 text__48-1024 text__48-sm">Trusted by leading brands <br className="d-none d-sm-block" /> and startups</h3>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10">
                <SliderTestimonial />
              </div>
            </div>
          </div>
        </section>

        <Opportunity />

        <Footer />

      </div >
    </Fragment >
  );
};

export default Index;
