import React, { Component, useState } from 'react'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const ResetPassword = (props) => {
    return (
        <Fragment>
            <div className="overflow-hidden position-relative w-100">
                <div className="wrapper__form-auth min-height-100">
                    <div className="content__center d-flex align-items-center justify-content-center">

                        <div className="row w-100 justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                <div className="text-center mb-3">
                                    <div
                                        class="navbar-brand medium font__size--20 text__20-1024 medium color__black"
                                    >
                                        <div className="d-flex align-items-center">
                                            <img src="./../images/Wisc.png" alt="" width={100} />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <h5 className='medium font__size--32 text__32-1024'>Reset Password</h5>
                                    <p className='medium font__size--16 text__16-1024 color__gray-1'>Recover your account password</p>
                                </div>
                                <div className="mb-4">
                                    <label className="medium font__size--14 text__14-1024 color__gray-3">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        className="font__size--16 text__16-1024 normal wrapper__input-field color__gray-2 form-control"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <NavLink to="/new-password" className='medium text-center font__size--16 text__16-1024 btn__auth w-100 rounded__50'>Next</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default ResetPassword